<template>
  <div class="height-100 max_box">
    <!-- <div class="audio-box">
      <audio src="audio/jijin/AVeryHappyChristmas.mp3" ref="bgAudio"></audio>
      <audio src="audio/jijin/1基金信息.mp3" ref="audio1"></audio>
      <audio src="audio/jijin/2收益率走势.mp3" ref="audio2"></audio>
      <audio src="audio/jijin/3收益倍数.mp3" ref="audio2_1"></audio>
      <audio src="audio/jijin/5同类基金排名.mp3" ref="audio3_2"></audio>
      <audio src="audio/jijin/4同类平均年化回报.mp3" ref="audio3"></audio>
      <audio src="audio/jijin/6这只产品究竟怎么样？.mp3" ref="audio4"></audio>
      <audio src="audio/jijin/7基金收益能力.mp3" ref="audio5"></audio>
      <audio src="audio/jijin/8持仓情况.mp3" ref="audio6"></audio>
      <audio src="audio/jijin/9基金经理表现如何？.mp3" ref="audio7"></audio>
      <audio src="audio/jijin/10基本信息.mp3" ref="audio8"></audio>
      <audio src="audio/jijin/11运作指数.mp3" ref="audio9"></audio>
      <audio src="audio/jijin/12近一年最大回撤.mp3" ref="audio10"></audio>
      <audio src="audio/jijin/13十大重仓股.mp3" ref="audio11"></audio>
      <audio src="audio/jijin/14风险提示.mp3" ref="audio12"></audio>
    </div> -->
    <div class="card animate__animated animate__fadeIn duration" id="card1">
      <div class="card1_top_box">
        <p class="font-blod">前海开源沪港深新硬件A</p>
        <p>004314</p>
        <div>
          <div>
            <p>成立日期</p>
            <p>2017-03-13</p>
          </div>
          <div>
            <p>基金类型</p>
            <p>灵活配置型</p>
          </div>
          <div>
            <p>风险等级</p>
            <p>高风险</p>
          </div>
        </div>
      </div>
      <div class="tips">
        <p>本视频相关内容不作为基金宣传推介材料</p>
        <p>数据来源：银河证券</p>
      </div>
    </div>
    <div
      class="card animate__animated animate__fadeIn duration none"
      id="card2"
    >
      <div class="card_main">
        <div class="title">
          <p class="title_l">收益走势</p>
          <p class="title_r">
            <span> 前海开源沪港深新硬件A</span><span>沪深300</span>
          </p>
        </div>
        <div class="content_box relative">
          <div id="chart1" class="chart"></div>
          <div class="ab_center content_box_p none">
            <p><span>3年</span>净值增长</p>
            <p><span class="large">1.7</span>倍</p>
          </div>
        </div>
      </div>
      <div class="tips">
        <p>本视频相关内容不作为基金宣传推介材料</p>
        <p>数据来源：银河证券</p>
      </div>
    </div>
    <div
      class="card animate__animated animate__fadeIn duration none"
      id="card3"
    >
      <div class="card_main card3_cardmain">
        <div class="title">
          <p class="title_l">业绩表现</p>
          <div class="card3_des">
            <div class="font-16 title_des1">
              <span>同期同类年化回报</span
              ><span class="color_EF622E card3_num">25.94%</span>
            </div>
            <div class="font-16 title_des2 none">
              <span>本基金年化回报</span
              ><span class="color_EF622E card3_num">38.99%</span>
            </div>
            <div class="font-16 title_des3 none">
              <span>超过同类平均</span
              ><span class="color_EF622E card3_num">13.05%</span>
            </div>
          </div>
        </div>
        <div class="content_box relative">
          <div id="chart2" class="chart"></div>
          <div class="ab_center card3_abcenter relative none">
            <p class="card3_p1 none">
              <span>近3年年化回报在同类型基金中排名</span>
            </p>
            <p class="color_EF622E card3_p2 none">
              <span class="large">TOP309</span>
            </p>
          </div>
        </div>
      </div>
      <div class="tips">
        <p>本视频相关内容不作为基金宣传推介材料</p>
        <p>数据来源：银河证券</p>
      </div>
    </div>
    <div
      class="card animate__animated animate__fadeIn duration none"
      id="card4"
    >
      <div class="card_main">
        <div class="content_box relative">
          <div class="move_1">
            <p style="font-size:font-size: 42px;font-weight: bold;">
              这只产品究竟怎么样?
            </p>
          </div>
        </div>
      </div>
      <div class="tips">
        <p>本视频相关内容不作为基金宣传推介材料</p>
        <p>数据来源：银河证券</p>
      </div>
    </div>
    <div
      class="card animate__animated animate__fadeIn duration none"
      id="card5"
    >
      <div class="card_main">
        <div class="title">
          <p class="title_l">基金收益能力</p>
        </div>
        <div class="content_box">
          <div>
            <p>近1年收益率</p>
            <p><span id="num2"></span>%</p>
          </div>
          <div>
            <p>同类基金对比</p>
            <p><span class="compare">超</span><span>80</span>%</p>
          </div>
        </div>
      </div>
      <div class="tips">
        <p>本视频相关内容不作为基金宣传推介材料</p>
        <p>数据来源：银河证券</p>
      </div>
    </div>
    <div
      class="card animate__animated animate__fadeIn duration none"
      id="card6"
    >
      <div class="card_main">
        <div class="title">
          <p class="title_l">基金持仓情况</p>
        </div>
        <div class="content_box relative">
          <div id="chart3" class="chart"></div>
        </div>
      </div>
      <div class="tips">
        <p>本视频相关内容不作为基金宣传推介材料</p>
        <p>数据来源：银河证券</p>
      </div>
    </div>
    <div
      class="card animate__animated animate__fadeIn duration none"
      id="card7"
    >
      <div class="card_main">
        <div class="content_box relative">
          <div class="move_3">
            <p>基金经理表现如何?</p>
          </div>
        </div>
      </div>
      <div class="tips">
        <p>本视频相关内容不作为基金宣传推介材料</p>
        <p>数据来源：银河证券</p>
      </div>
    </div>
    <div
      class="card animate__animated animate__fadeIn duration none"
      id="card8"
    >
      <div class="card_main">
        <div class="title">
          <p class="title_l">基金经理</p>
        </div>
        <div class="content_box">
          <div class="flex align-center">
            <p><img src="../../assets/img/jijinmanager.png" alt="" /></p>
            <div class="flex1">
              <p class="flex flex-between font-24 margin-l-8 name_box">
                <span>魏淳</span><span><i id="num1"></i>%</span>
              </p>
              <div class="flex flex-between margin-l-8 des_box">
                <p>
                  <span>硕士</span>
                  <span class="margin-l-8 margin-r-8">|</span>
                  <span>前海开源基金</span>
                </p>
                <p>偏好成长行业</p>
              </div>
            </div>
          </div>
          <div class="flex flex-between label_box">
            <div>
              <p>任职年限</p>
              <p>2.6年</p>
            </div>
            <div class="margin-l-8 margin-r-8">
              <p>管理规模</p>
              <p>18.77亿</p>
            </div>
            <div>
              <p>重仓行业</p>
              <p>科技业</p>
            </div>
          </div>
        </div>
      </div>
      <div class="tips">
        <p>本视频相关内容不作为基金宣传推介材料</p>
        <p>数据来源：银河证券</p>
      </div>
    </div>
    <div
      class="card animate__animated animate__fadeIn duration none"
      id="card9"
    >
      <div class="card_main">
        <div class="title">
          <p class="title_l">基金经理运作指数<span>（任职以来）</span></p>
          <!-- <p class="title_r">
            <span>基金经理</span><span>同类平均</span><span>沪深300</span>
          </p> -->
        </div>
        <div class="content_box">
          <div id="chart4" class="chart"></div>
        </div>
      </div>
      <div class="tips">
        <p>本视频相关内容不作为基金宣传推介材料</p>
        <p>数据来源：银河证券</p>
      </div>
    </div>
    <div
      class="card animate__animated animate__fadeIn duration none"
      id="card10"
    >
      <div class="card_main">
        <div class="title">
          <p class="title_l">基金抗跌能力</p>
          <p class="font-16">近一年</p>
        </div>
        <div class="content_box">
          <div>
            <p>最大回撤</p>
            <p><span id="num3"></span>%</p>
          </div>
          <div>
            <p>同类排名（共2685支）</p>
            <p>NO.370</p>
          </div>
        </div>
      </div>
      <div class="tips">
        <p>本视频相关内容不作为基金宣传推介材料</p>
        <p>数据来源：银河证券</p>
      </div>
    </div>
    <div
      class="card animate__animated animate__fadeIn duration none"
      id="card11"
    >
      <div class="card_main">
        <div class="title">
          <p class="title_l">十大重仓股</p>
        </div>
        <div class="content_box">
          <div id="chart5" class="chart"></div>
        </div>
      </div>
      <div class="tips">
        <p>本视频相关内容不作为基金宣传推介材料</p>
        <p>数据来源：银河证券</p>
      </div>
    </div>
    <div
      class="card animate__animated animate__fadeIn duration none"
      id="card12"
    >
      <div class="card_main">
        <div class="title">
          <p class="title_l">风险提示</p>
        </div>
        <div class="content_box relative">
          <div>
            <p>
              上述内容仅供投资者参考，不构成投资建议，据此操作，风险自担。
              基金的过往业绩并不预示其未来表现，基金管理人管理的其他基金的业绩并
              不构成基金业绩表现的保证。
              请您认真阅读《基金合同》等法律文件，结合自身风险承受能力选择合适的产品。市场有风险，投资需谨慎。
            </p>
            <!-- <p>
              基金的过往业绩并不预示其未来表现，基金管理人管理的其他基金的业绩并
              不构成基金业绩表现的保证。
            </p>
            <p>
              请您认真阅读《基金合同》等法律文件，结合自身风险承受能力选择合适的产品。
            </p>
            <p>市场有风险，投资需谨慎。</p> -->
          </div>
        </div>
      </div>
      <div class="tips">
        <p>本视频相关内容不作为基金宣传推介材料</p>
        <p>数据来源：银河证券</p>
      </div>
    </div>
    <div
      class="card_heng2 animate__animated animate__fadeIn duration none"
      id="card13"
    >
      <div class="width-100 height-100">
        <video
          class="end_video"
          src="video/huaanend.mov"
          muted
          width="100%"
          height="100%"
          ref="endVideo"
        ></video>
      </div>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import { GetParams2 } from "../../utils/common";
import ApiServe from "@/api/index";
export default {
  name: "Template102",
  data() {
    return {
      device_id: null,
      options: {
        useEasing: true, // 过渡动画效果，默认ture
        useGrouping: false, // 千分位效果，例：1000->1,000。默认true
        //separator: ',',   // 使用千分位时分割符号
        decimals: 2, // 小数位分割符号
        prefix: "", // 前置符号
        suffix: "", // 后置符号，可汉字
      },
      chart2: {
        x: ["前海开源沪港深A", "同类平均", "沪深300", "业绩比较基准"],
        y: [38.99, 25.94, 11.09, 10.21],
      },
      chart3: [
        //yangshi
        { value: 45.85, name: "制造业" },
        { value: 6.49, name: "信息服务业" },
        { value: 47.66, name: "其他" },
      ],
      chart5: [
        {
          name: "中国电信",
          value: 8.72,
        },
        {
          name: "腾讯控股",
          value: 7.47,
        },
        {
          name: "中国移动",
          value: 7.45,
        },
        {
          name: "立讯精密",
          value: 7.07,
        },
        {
          name: "紫光股份",
          value: 6.77,
        },
        {
          name: "宝信软件",
          value: 6.49,
        },
        {
          name: "舜宇光学科技",
          value: 5.84,
        },
        {
          name: "鹏鼎控股",
          value: 5.51,
        },
        {
          name: "瑞声科技",
          value: 4.46,
        },
        {
          name: "亿联网络",
          value: 3.86,
        },
      ],
      // device_id:null,
      id: "",
      model_id: null,
      model_name: "",
      model_category: "",
      url_extra_params: null,
      ip: "",
      port: "",
      offset_x: "",
      offset_y: "",
      width: "",
      height: "",
      source: "",
      aduioData: [],
    };
  },
  created() {
    this.id = GetParams2().id || null;
    this.model_id = GetParams2().model_id || 1;
    this.model_name = GetParams2().model_name || null;
    this.model_category = GetParams2().model_category || null;
    this.url_extra_params = GetParams2().url_extra_params || null;
    this.ip = GetParams2().ip || null;
    this.port = GetParams2().port || null;
    this.offset_x = GetParams2().offset_x || null;
    this.offset_y = GetParams2().offset_y || null;
    this.width = GetParams2().width || null;
    this.height = GetParams2().height || null;
    this.source = GetParams2().source || null;
  },
  computed: {
    newIpPort() {
      if (this.source == "ali") {
        return "https://recordvideoali.wedengta.com";
      } else {
        return "https://recordvideo.wedengta.com";
      }
    },
  },
  mounted() {
    // this.loadChart1();
    // this.loadChart2();
    // this.loadChart3();
    // this.loadChart4();
    // this.loadChart5();

    if (this.ip && this.port) {
      const params = {
        ip: this.ip,
        port: this.port,
        offset_x: this.offset_x,
        offset_y: this.offset_y,
        width: this.width,
        height: this.height,
      };
      ApiServe.recordstartPc(this.newIpPort, params)
        .then(({ code }) => {
          this.load();
        })
        .catch((err) => {
          ApiServe.recordreleasePc(this.newIpPort, {
            ip: this.ip,
            port: this.port,
          });
        });
    } else {
      // alert("ip，port没获取到");
      this.load();
    }
  },
  methods: {
    // 数字滚动
    startNumber(vdom, number, point) {
      let num = new CountUp(vdom, 0, number, point, 2, this.options);
      num.start();
    },
    // 收益走势
    loadChart1(color1 = "#EF622E", color2 = "#7E8090", isShow = true) {
      var myChart = this.$echarts.init(document.getElementById("chart1"));
      var option = {
        xAxis: {
          type: "category",
          data: [
            "2018/07",
            "2018/11",
            "2019/02",
            "2019/08",
            "2021/01",
            "2021/05",
            "2021/09",
          ],
          axisTick: {
            show: false,
          },
          axisLine: {
            lineStyle: {
              color: "#DEDFE6",
            },
          },
          axisLabel: {
            color: "#7E8090",
            fontSize: 24,
          },
        },
        grid: {
          left: 19,
          bottom: 60,
        },
        animationDuration: 4000,
        yAxis: {
          type: "value",
          axisLine: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: "rgba(222, 223, 230, 0.4)",
            },
          },
        },
        series: [
          {
            data: [100, 120, 110, 160, 180, 170, 240],
            type: "line",
            color: color1,
            showSymbol: false,
            endLabel: {
              show: isShow,
              offset: [-50, -20],
              backgroundColor: "#F5F2F2",
              padding: 10,
              borderRadius: 8,
              color: "#EF622E",
              formatter: function (params) {
                return "168.53%";
              },
              fontSize: 31,
            },
            lineStyle: {
              width: 8,
            },
          },
          {
            data: [80, 100, 90, 120, 160, 150, 180],
            type: "line",
            color: color2,
            showSymbol: false,
            endLabel: {
              show: isShow,
              offset: [-50, 20],
              backgroundColor: "#F5F2F2",
              padding: 10,
              borderRadius: 8,
              color: "#7E8090",
              formatter: function (params) {
                return "51.91%";
              },
              fontSize: 31,
            },
            lineStyle: {
              width: 8,
            },
          },
        ],
      };
      myChart.setOption(option);
    },
    // 业绩表现--柱状图
    loadChart2() {
      const colors = ["#EF622E", "#FFBB81", "#38A8FA", "#7E8090"];
      var myChart = this.$echarts.init(document.getElementById("chart2"));
      var option = {
        grid: {
          left: 10,
          bottom: 60,
          right: 10,
        },
        xAxis: {
          type: "category",
          data: this.chart2.x,
          axisTick: {
            show: false,
          },
          axisLine: {
            lineStyle: {
              color: "#DEDFE6",
            },
          },
          axisLabel: {
            color: "#7E8090",
            interval: 0,
            fontSize: 24,
          },
        },
        animationDuration: 4000,
        yAxis: {
          axisLine: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: "rgba(222, 223, 230, 0.4)",
            },
          },
        },
        series: [
          {
            type: "bar",
            barWidth: 40,
            label: {
              show: true,
              position: "top",
              fontSize: 24,
              formatter: function ({ value }) {
                return value + "%";
              },
              color: "inherit",
            },
            data: this.chart2.y,
            itemStyle: {
              color: function (val) {
                return colors[val.dataIndex];
              },
            },
          },
        ],
      };
      myChart.setOption(option);
    },
    // 基金持仓情况
    loadChart3() {
      var myChart = this.$echarts.init(document.getElementById("chart3"));
      var option = {
        series: [
          {
            type: "pie",
            radius: ["30%", "60%"],
            startAngle: 190,
            minAngle: 30,
            animationDuration: 2000,
            labelLine: {
              length: 40,
              length2: 20,
            },
            label: {
              color: "inherit",
              formatter: function ({ value, name }) {
                return name + "\n\n" + value + "%";
              },
              fontSize: 23,
            },
            data: this.chart3,
            itemStyle: {
              normal: {
                color: function (params) {
                  //自定义颜色
                  var colorList = ["#EF622E", "#C3A178", "#5B72B1", "#aaa"];
                  return colorList[params.dataIndex];
                },
              },
            },
          },
        ],
      };
      myChart.setOption(option);
    },
    // 基金经理运作指数
    loadChart4() {
      var myChart = this.$echarts.init(document.getElementById("chart4"));
      var option = {
        xAxis: {
          type: "category",
          data: [
            "2018/10",
            "2018/11",
            "2019/02",
            "2019/08",
            "2021/01",
            "2021/05",
            "2021/10",
          ],
          axisTick: {
            show: false,
          },
          axisLine: {
            lineStyle: {
              color: "#DEDFE6",
            },
          },
          axisLabel: {
            color: "#7E8090",
            fontSize: 24,
          },
        },
        grid: {
          left: 10,
          bottom: 60,
        },
        legend: {
          data: ["同类平均", "沪深300", "前海开源沪港深新硬件A"],
          left: 0,
          itemHeight: 5,
          top: 16,
          textStyle: {
            fontSize: 24,
          },
        },
        animationDuration: 3000,
        yAxis: {
          type: "value",
          axisLine: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: "rgba(222, 223, 230, 0.4)",
            },
          },
        },
        series: [
          {
            data: [447.6, 522.2, 596.8, 671.4, 820.6, 1044.4, 1342.8],
            type: "line",
            name: "同类平均",
            color: "#C3A178",
            showSymbol: false,
            endLabel: {
              show: true,
              offset: [-50, 0],
              backgroundColor: "#F5F2F2",
              padding: 10,
              borderRadius: 36,
              color: "#C3A178",
              formatter: function (params) {
                return "76.24%";
              },
              fontSize: 23,
            },
            lineStyle: {
              width: 8,
            },
          },
          {
            data: [304.8, 355.6, 406.4, 457.2, 558.8, 711.2, 914.4],
            type: "line",
            name: "沪深300",
            color: "#7E8090",
            showSymbol: false,
            endLabel: {
              show: true,
              offset: [-50, 20],
              backgroundColor: "#F5F2F2",
              padding: 10,
              borderRadius: 36,
              color: "#7E8090",
              formatter: function (params) {
                return "51.91%";
              },
              fontSize: 23,
            },
            lineStyle: {
              width: 8,
            },
          },
          {
            data: [120, 140, 160, 180, 220, 280, 360],
            type: "line",
            name: "前海开源沪港深新硬件A",
            color: "#EF622E",
            showSymbol: false,
            endLabel: {
              show: true,
              offset: [-50, -20],
              backgroundColor: "#F5F2F2",
              padding: 10,
              borderRadius: 36,
              color: "#EF622E",
              formatter: function (params) {
                return "20.42%";
              },
              fontSize: 23,
            },
            lineStyle: {
              width: 8,
            },
          },
        ],
      };
      myChart.setOption(option);
    },
    // 十大重仓持股
    loadChart5() {
      var myChart = this.$echarts.init(document.getElementById("chart5"));
      // 指定图表的配置项和数据
      let option = {
        legend: {
          show: false,
        },
        series: [
          {
            type: "treemap",
            data: this.chart5,
            left: 20,
            top: 20,
            bottom: 20,
            right: 20,
            //animationDuration: 4000,
            breadcrumb: {
              show: false,
            },
            levels: [
              {
                // color: [
                //   "RGBA(239, 98, 46, 1)",
                //   "RGBA(239, 98, 46, 0.95)",
                //   "RGBA(239, 98, 46, 0.9)",
                //   "RGBA(239, 98, 46, 0.85)",
                //   "RGBA(239, 98, 46, 0.8)",
                //   "RGBA(239, 98, 46, 0.75)",
                //   "RGBA(239, 98, 46, 0.7)",
                //   "RGBA(239, 98, 46, 0.65)",
                //   "RGBA(239, 98, 46, 0.6)",
                //   "RGBA(239, 98, 46, 0.55)",
                //   "RGBA(239, 98, 46, 0.5)",
                // ],
                color: ["#EF622E"],
              },
            ],
            label: {
              show: true,
              color: "#fff",
              formatter: function (value) {
                return value.name + "\n" + value.value + "%";
              },
              fontSize: 20,
            },
          },
        ],
      };
      myChart.setOption(option);
    },
    endRecord() {
      this.aduioData = [
        {
          audioLink:
            "http://idtcdn.oss-cn-hangzhou.aliyuncs.com/external/yinhe/jijin/1.mp3",
          timeIndex: 0,
          audioLen: 3,
        },
        {
          audioLink:
            "http://idtcdn.oss-cn-hangzhou.aliyuncs.com/external/yinhe/jijin/2.mp3",
          timeIndex: 4,
          audioLen: 5,
        },
        {
          audioLink:
            "http://idtcdn.oss-cn-hangzhou.aliyuncs.com/external/yinhe/jijin/3.mp3",
          timeIndex: 10,
          audioLen: 1,
        },
        {
          audioLink:
            "http://idtcdn.oss-cn-hangzhou.aliyuncs.com/external/yinhe/jijin/4.mp3",
          timeIndex: 13,
          audioLen: 9,
        },
        {
          audioLink:
            "http://idtcdn.oss-cn-hangzhou.aliyuncs.com/external/yinhe/jijin/5.mp3",
          timeIndex: 24,
          audioLen: 4,
        },
        {
          audioLink:
            "http://idtcdn.oss-cn-hangzhou.aliyuncs.com/external/yinhe/jijin/6.mp3",
          timeIndex: 29,
          audioLen: 1,
        },
        {
          audioLink:
            "http://idtcdn.oss-cn-hangzhou.aliyuncs.com/external/yinhe/jijin/7.mp3",
          timeIndex: 32,
          audioLen: 5,
        },
        {
          audioLink:
            "http://idtcdn.oss-cn-hangzhou.aliyuncs.com/external/yinhe/jijin/8.mp3",
          timeIndex: 40,
          audioLen: 7,
        },
        {
          audioLink:
            "http://idtcdn.oss-cn-hangzhou.aliyuncs.com/external/yinhe/jijin/9.mp3",
          timeIndex: 50,
          audioLen: 1,
        },
        {
          audioLink:
            "http://idtcdn.oss-cn-hangzhou.aliyuncs.com/external/yinhe/jijin/10.mp3",
          timeIndex: 53,
          audioLen: 5,
        },
        {
          audioLink:
            "http://idtcdn.oss-cn-hangzhou.aliyuncs.com/external/yinhe/jijin/11.mp3",
          timeIndex: 59,
          audioLen: 4,
        },
        {
          audioLink:
            "http://idtcdn.oss-cn-hangzhou.aliyuncs.com/external/yinhe/jijin/12.mp3",
          timeIndex: 66,
          audioLen: 5,
        },
        {
          audioLink:
            "http://idtcdn.oss-cn-hangzhou.aliyuncs.com/external/yinhe/jijin/13.mp3",
          timeIndex: 73,
          audioLen: 4,
        },
        {
          audioLink:
            "http://idtcdn.oss-cn-hangzhou.aliyuncs.com/external/yinhe/jijin/14.mp3",
          timeIndex: 79,
          audioLen: 11,
        },
      ];

      const data = {
        id: this.id,
        model_id: this.model_id,
        videoTotalTime: 92,
        videmBgm:
          "http://idtcdn.oss-cn-hangzhou.aliyuncs.com/external/yinhe/jijin/AVeryHappyChristmas.mp3",
        audiosOrderList: this.aduioData,
      };
      ApiServe.recordstopPc(this.newIpPort, { ip: this.ip, port: this.port })
        .then(() => {
          ApiServe.combineaudioswithbgm(
            this.newIpPort,
            this.ip,
            this.port,
            data
          )
            .then(() => {
              ApiServe.recordreleasePc(this.newIpPort, {
                ip: this.ip,
                port: this.port,
              });
            })
            .catch(() => {
              ApiServe.recordreleasePc(this.newIpPort, {
                ip: this.ip,
                port: this.port,
              });
            });
        })
        .catch(() => {
          ApiServe.recordreleasePc(this.newIpPort, {
            ip: this.ip,
            port: this.port,
          });
        });
    },
    load() {
      // this.$refs.bgAudio.volume = 0.4; //背景音乐音量调节（1为最大）
      // this.$refs.bgAudio.play(); //背景音乐播放
      // this.$refs.audio1.play();
      // this.$refs.video1.play(); //首页的视频播放
      setTimeout(() => {
        $("#card1").addClass("animate__fadeOut");
      }, 3000);
      setTimeout(() => {
        $("#card1").addClass("none");
        $("#card2").removeClass("none");
        this.loadChart1();
        // this.$refs.audio2.play();
      }, 4000); //间隔
      setTimeout(() => {
        $(".content_box_p").fadeIn("slow");
      }, 9000);
      setTimeout(() => {
        // this.$refs.audio2_1.play();
      }, 10000);

      setTimeout(() => {
        $("#card2").addClass("animate__fadeOut");
      }, 12000);

      setTimeout(() => {
        $("#card2").addClass("none");
        $("#card3").removeClass("none");
        this.loadChart2();
        // this.$refs.audio3.play();
      }, 13000); //间隔
      setTimeout(() => {
        $(".title_des1").css("display", "none");
        $(".title_des2").fadeIn("slow");
      }, 16000);
      setTimeout(() => {
        $(".title_des2").css("display", "none");
        $(".title_des3").fadeIn("slow");
      }, 18000);
      setTimeout(() => {
        $(".card3_abcenter").css("display", "block");
        $(".card3_abcenter").animate({ right: "0px" }, 2000);
      }, 22000);
      setTimeout(() => {
        $(".card3_p1").fadeIn("slow");
        $(".card3_p2").fadeIn("slow");
        // this.$refs.audio3_2.play();
      }, 24000);

      setTimeout(() => {
        $("#card3").addClass("animate__fadeOut");
      }, 28000);
      setTimeout(() => {
        $("#card3").addClass("none");
        $("#card4").removeClass("none");
        $(".move_1").animate({ left: "50%", top: "50%" }, 1000);
        // this.$refs.audio4.play();
      }, 29000); //间隔

      setTimeout(() => {
        $("#card4").addClass("animate__fadeOut");
      }, 31000);
      setTimeout(() => {
        $("#card4").addClass("none");
        $("#card5").removeClass("none");
        this.startNumber("num2", 25.69, 2);
        // this.$refs.audio5.play();
      }, 32000); //间隔

      setTimeout(() => {
        $("#card5").addClass("animate__fadeOut");
      }, 39000);
      setTimeout(() => {
        $("#card5").addClass("none");
        $("#card6").removeClass("none");
        this.loadChart3();
        // this.$refs.audio6.play();
      }, 40000); //间隔

      setTimeout(() => {
        $("#card6").addClass("animate__fadeOut");
      }, 49000);
      setTimeout(() => {
        $("#card6").addClass("none");
        $("#card7").removeClass("none");
        $(".move_3").animate({ left: "50%", top: "50%" }, 1000);
        // this.$refs.audio7.play();
      }, 50000); //间隔
      setTimeout(() => {
        $("#card7").addClass("animate__fadeOut");
      }, 52000);
      setTimeout(() => {
        $("#card7").addClass("none");
        $("#card8").removeClass("none");
        this.startNumber("num1", 20.42, 2);
        // this.$refs.audio8.play();
      }, 53000); //间隔
      setTimeout(() => {
        $("#card8").addClass("animate__fadeOut");
      }, 58000);
      setTimeout(() => {
        $("#card8").addClass("none");
        $("#card9").removeClass("none");
        this.loadChart4();
        // this.$refs.audio9.play();
      }, 59000); //间隔
      setTimeout(() => {
        $("#card9").addClass("animate__fadeOut");
      }, 65000);
      setTimeout(() => {
        $("#card9").addClass("none");
        $("#card10").removeClass("none");
        this.startNumber("num3", 22.67, 2);
        // this.$refs.audio10.play();
      }, 66000); //间隔
      setTimeout(() => {
        $("#card10").addClass("animate__fadeOut");
      }, 72000);
      setTimeout(() => {
        $("#card10").addClass("none");
        $("#card11").removeClass("none");
        this.loadChart5();
        // this.$refs.audio11.play();
      }, 73000); //间隔
      setTimeout(() => {
        $("#card11").addClass("animate__fadeOut");
      }, 78000);
      setTimeout(() => {
        $("#card11").addClass("none");
        $("#card12").removeClass("none");
        // this.$refs.audio12.play();
      }, 79000); //间隔)
      setTimeout(() => {
        $("#card12").addClass("animate__fadeOut");
      }, 90000);
      setTimeout(() => {
        $("#card12").addClass("none");
        $("#card13").removeClass("none");
        this.$refs.endVideo.play();
      }, 91000); //间隔
      // 总时间 91 + 5秒
      if (this.ip) {
        setTimeout(() => {
          this.endRecord();
        }, 92000);
      }
    },
  },
};
</script>
<style scoped lang="less">
.max_box {
  background: url("../../assets/img/bg.png") no-repeat center center;
  background-size: cover;
  font-size: 12px;
}
.font-16 {
  font-size: 31px;
  font-weight: bold;
}
.card {
  color: #7e8090;
  font-size: 36px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border-radius: 12px;
  background: transparent;
  overflow: hidden;
}
.color_EF622E {
  color: #ef622e;
}
.chart {
  height: 100%;
  width: 100%;
}
.tips {
  position: absolute;
  bottom: 112px;
  left: 10px;
  right: 10px;
  color: #878998;
  font-size: 21px;
  width: 378px;
  height: 57px;
  margin: auto;
  p {
    text-align: center;
    line-height: 36px;
  }
}
.card_main {
  position: absolute;
  top: 226px;
  left: 16px;
  right: 16px;
  bottom: 0;
}
.ab_center {
  position: absolute;
  left: 19px;
  right: 20px;
  top: 23px;
  bottom: 28px;
  background: rgba(231, 232, 242, 0.9);
}
.content_box {
  width: 686px;
  height: 486px;
  background: #ffffff;
  border: 1px solid #f5f2f2;
  box-shadow: 0px 12px 24px rgba(126, 128, 144, 0.16);
  opacity: 1;
  border-radius: 24px;
  .content_box_p {
    > p:nth-of-type(1) {
      font-size: 32px;
      font-weight: bold;
      color: #7e8090;
    }
    > p:nth-of-type(2) {
      font-size: 132px;
      font-weight: bold;
      color: #ef622e;
      height: 80px;
    }
  }
}
.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 58px;
  line-height: 30px;
  margin-bottom: 33px;
  .title_l {
    height: 58px;
    font-size: 42px;
    color: #7e8090;
    font-weight: bold;
  }
  .title_r {
    height: 58px;
    > span:nth-of-type(1) {
      color: #ef622e;
      margin-right: 20px;
      font-size: 24px;
    }
    > span:nth-of-type(1)::before {
      content: "";
      display: inline-block;
      width: 16px;
      height: 5px;
      margin-bottom: 7px;
      margin-right: 14px;
      background: #ef622e;
    }
    > span:nth-of-type(2) {
      color: #7e8090;
      font-size: 24px;
    }
    > span:nth-of-type(2)::before {
      content: "";
      display: inline-block;
      width: 16px;
      height: 5px;
      margin-bottom: 7px;
      margin-right: 14px;
      background: #7e8090;
    }
  }
}
.card1_top_box {
  position: absolute;
  top: 213px;
  left: 101px;
  right: 101px;
  text-align: center;
  > p:first-of-type {
    font-size: 47px;
    font-weight: bold;
    color: #3c3c3c;
  }
  > p:nth-of-type(2) {
    width: 160px;
    height: 48px;
    margin: 35px auto;
    background: #f5f2f2;
    border-radius: 36px;
    font-size: 31px;
    color: #ef622e;
    font-weight: bold;
    text-align: center;
    line-height: 40px;
    margin-bottom: 217px;
  }
  > div {
    > div {
      text-align: center;
      margin-bottom: 33px;
      p:first-of-type {
        color: #7e8090;
        font-size: 24px;
        margin-bottom: 16px;
      }
      p:nth-of-type(2) {
        width: 160px;
        height: 48px;
        line-height: 46px;
        background: #fff;
        border-radius: 36px;
        font-size: 23px;
        margin: 4px auto;
        font-weight: bold;
        color: #3c3c3c;
      }
    }
  }
}
#card2 {
  .ab_center {
    p:nth-of-type(1) {
      position: absolute;
      left: 10px;
      right: 10px;
      top: 95px;
      text-align: center;
    }
    p:nth-of-type(2) {
      position: absolute;
      left: 10px;
      right: 10px;
      top: 140px;
      text-align: center;
      font-size: 32px;
    }
  }
}
#card3 .card_main {
  .card3_des {
    flex: 1;
    height: 58px;
    > div {
      text-align: right;
    }
    .card3_num {
      font-size: 31px;
    }
  }
  .ab_center {
    // left: 61px;
    left: 17.76%;
    right: 286px;
    top: 128px;
    bottom: 28px;
    background: rgba(231, 232, 242, 1);
    text-align: center;
    p:nth-of-type(1) {
      position: absolute;
      left: 0;
      right: 0;
      top: 58px;
      font-size: 24px;
    }
    p:nth-of-type(2) {
      position: absolute;
      left: 0;
      right: 0;
      top: 100px;
    }
  }
}
#card5 {
  .content_box {
    text-align: center;
    > div:first-of-type {
      margin-top: 95px;
    }
    > div {
      > p:nth-of-type(1) {
        font-size: 24px;
        color: #7e8090;
      }
      > p:nth-of-type(2) {
        font-size: 84px;
        color: #ef622e;
        .compare {
          font-size: 24px;
        }
      }
    }
  }
}
#card8 {
  .content_box {
    padding: 0 16px;
    box-sizing: border-box;
    > div:first-of-type {
      margin-top: 26px;
      margin-bottom: 114px;
      img {
        width: 120px;
        height: 120px;
      }
    }
    .name_box {
      font-weight: bold;
      font-size: 48px;
      span:nth-of-type(1) {
        color: #3c3c3c;
      }
      span:nth-of-type(2) {
        color: #ef622e;
      }
    }
    .des_box {
      margin-top: 4px;
      font-size: 24px;
    }
    .label_box {
      margin-top: 56px;
      > div {
        text-align: center;
        font-size: 24px;
        > p:nth-of-type(2) {
          margin-top: 8px;
          color: #3c3c3c;
          width: 160px;
          height: 48px;
          line-height: 45px;
          font-weight: bold;
          border-radius: 36px;
          background: #f5f2f2;
          text-align: center;
        }
      }
    }
  }
}
.move_1,
.move_3 {
  position: absolute;
  left: 0;
  top: 0;
  text-align: center;
  transform: translate(-50%, -50%);
  p {
    padding: 12px 16px;
    background: #7e8090;
    color: #fff;
    font-size: 42px;
    white-space: nowrap;
  }
}
.move4 {
  top: 80%;
}
.large {
  font-size: 132px;
}
#num2 {
  font-size: 84px;
}
#card9 {
  .card_main {
    .title_l {
      font-size: 42px;
      span {
        font-size: 36px;
      }
    }
    .title_r {
      > span:nth-of-type(2) {
        color: #c3a178;
        margin-right: 20px;
      }
      > span:nth-of-type(2)::before {
        content: "";
        display: inline-block;
        width: 8px;
        height: 2px;
        margin-bottom: 4px;
        margin-right: 4px;
        background: #c3a178;
      }
      > span:nth-of-type(3) {
        color: #636679;
      }
      > span:nth-of-type(3)::before {
        content: "";
        display: inline-block;
        width: 8px;
        height: 2px;
        margin-bottom: 4px;
        margin-right: 4px;
        background: #636679;
      }
    }
  }
}
#card10 {
  .content_box {
    text-align: center;
    color: #7e8090;
    > div {
      margin-top: 59px;
      > p:nth-of-type(1) {
        font-size: 32px;
      }
      > p:nth-of-type(2) {
        font-size: 64px;
        font-weight: bold;
      }
    }
  }
}
#card12 {
  .content_box {
    font-size: 32px;
    font-weight: normal;
    text-align: left;
    > div {
      margin-top: 65px;
    }
    p {
      margin: 77px 44px 84px 32px;
    }
  }
}
.card_heng2 {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
#chart1,
#chart2,
#chart3,
#chart4,
#chart5 {
  width: 686px;
  height: 486px;
}
</style>